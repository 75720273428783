import {
    configureStore,
    getDefaultMiddleware,
    createSlice,

} from "@reduxjs/toolkit";



import {getTrainingDataList, saveTrainingData,getTrainingDataFile,testModel,createModel,getModels} from "./asyncActions";
import {getRandomColor} from "../shared_functions";

const middleware = [
    ...getDefaultMiddleware(),
    /*YOUR CUSTOM MIDDLEWARES HERE*/
];
let preDefTags=[]
if (window._env_.REACT_APP_CUSTOM_TAGS){
     preDefTags=window._env_.REACT_APP_CUSTOM_TAGS.split(",")
}



const requestState = {
    tags:
        preDefTags.map(tag=>{return {tag,color:getRandomColor()}})

    ,
    activeTag:"TAG-0",
    models:[],
    loading:false,
    error:"",
    files:[],
    trainingData:[],
    testResult:""
};



const requestSlice = createSlice({
    name: "request",
    initialState: requestState,
    reducers: {

        resetState:(state)=> {
            return state = {
                tags:
                    preDefTags.map(tag=>{return {tag,color:getRandomColor()}})

                ,
                models:[],
                loading:false,
                error:"",
                activeTag:"TAG-0",
                files:[],
                trainingData:[],
                testResult:""
            };

        },


        setTags:(state,action)=> {

            if (Array.isArray(action.payload)){
                state.tags = action.payload;
            }
            return state;

        },
        setActiveTag:(state,action)=> {

            if (action.payload){
                state.activeTag = action.payload;
            }
            return state;

        },



    },
    extraReducers:{
        [saveTrainingData.pending]: state => {
            state.loading = true;
        },
        [saveTrainingData.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [saveTrainingData.fulfilled]: (state, action) => {
            state.loading = false;


        },
        [getTrainingDataList.pending]: state => {
            state.loading = true;
        },
        [getTrainingDataList.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getTrainingDataList.fulfilled]: (state, action) => {
            state.loading = false;
            state.files=action.payload

        },
        [getTrainingDataFile.pending]: state => {
            state.loading = true;
        },
        [getTrainingDataFile.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getTrainingDataFile.fulfilled]: (state, action) => {
            state.loading = false;
            state.trainingData=action.payload


        },
        [testModel.pending]: state => {
            state.loading = true;
        },
        [testModel.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [testModel.fulfilled]: (state, action) => {
            state.loading = false;
            state.testResult=action.payload


        },
        [createModel.pending]: state => {
            state.loading = true;
        },
        [createModel.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [createModel.fulfilled]: (state, action) => {
            state.loading = false;
            state.models=action.payload


        },[getModels.pending]: state => {
            state.loading = true;
        },
        [getModels.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getModels.fulfilled]: (state, action) => {
            state.loading = false;
            state.models=action.payload


        },

    }
});

export const { resetState,setTags,setActiveTag} = requestSlice.actions;

const requestReducer = requestSlice.reducer;

export default configureStore({
    reducer: {
        request: requestReducer,
    },
    middleware,
});



