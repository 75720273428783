
import React,{useState} from 'react'
import {Menu,Button,Modal,Input,Form} from "antd";
import Tag from "../Tag/Tag";
import {connect,useDispatch} from 'react-redux'
import {TagOutlined} from "@ant-design/icons"
import {CompactPicker} from "react-color";
import {setActiveTag, setTags} from "../util/redux";

const select = state=>{
    const tags=state.request.tags

    return {
        tags
    }
}
 function Navigation(props){

    const {tags}=props
    const dispatch=useDispatch()

    const items=[
        {
            label:<div><img src="manyoselement.png" height="25"/>SMILEconceal</div>,
            disabled:true
        },
        {
            type: 'divider',
        },
        {
            label:"Tags",
            key:"tags",
            children:
                [...tags.map((tag,i) => {
                    return  {label:
                            <Tag
                                val={tag.tag}
                                color={tag.color}
                                index={i}

                            />,
                        key:`TAG-${i}`}

                }),{
                    label:<Button

                            >add Tag</Button>
                    , key:`Add-Tag`}]
            ,type:"group"

        }

    ]
     function getRandomColor() {
         const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
         const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
         const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
         return '#' + red + green + blue;
     }
    const onClick = (e) => {


        let tagName=""
        let color=getRandomColor()
        const setTagName=(val)=>{
            tagName=val
        }

        const handleColorChange = ({ hex }) => color=hex





        if (e.key==="Add-Tag"){
            Modal.confirm({
                title: 'Add a new Tag',
                content: <Form>
                    <Form.Item label={"Name"}>
                        <Input placeholder={"Name"} onChange={(e)=>{setTagName(e.target.value)}}></Input>
                    </Form.Item>


                    <Form.Item label={"Color"}>

                                <CompactPicker
                                    color={color}
                                    onChangeComplete={ handleColorChange }
                                />
                    </Form.Item>




                    </Form>,
                onOk:()=>{
                    if (tagName!=""){
                        dispatch(setTags([...tags,{tag:tagName.toUpperCase(),color}]))
                    }
                }
            });
        }

        dispatch(setActiveTag(e.key))

    };
   return (<Menu
        onClick={onClick}
        style={{
            width: '100%',
        }}
        defaultSelectedKeys={['TAG-0']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
    />)

}


export default connect(select)(Navigation)
