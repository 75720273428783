import React,{useEffect,useState} from "react";

import {Space,Button,Input,Select,Form,Typography,message} from 'antd'
import {connect,useDispatch} from "react-redux";
import {testModel,getModels} from "../../util/redux/asyncActions";
import ManyosSpinner from "../../ManyosSpinner/ManyosSpinner";

const {Text} = Typography

const {TextArea} = Input
const select = state =>{
    const files = state.request.files
    const loading = state.request.loading
    const testResult = state.request.testResult
    const models=state.request.models
    return{
        files,
        models,
        testResult,
        loading
    }
}
function TestModel(props){

    const {files,loading,testResult,models} = props
    const dispatch = useDispatch()


    useEffect(()=>{
            dispatch(getModels())
    }

    ,[])

    const [testString,setTestString] = useState("")

    const [model,setModel] = useState()
    const [tags,setTags] = useState([])
    const [removeTags,setRemoveTags] = useState([])

    React.useEffect(()=>{

        setRemoveTags([])
    },[model])

    const handleTestStringChange = (e)=>{
        setTestString(e.target.value)
    }
    const handleModelSelect = (e)=>{


        const selModel=models.find(model=>model.model===e)
        if (selModel && selModel.entities && selModel.entities.length>0){
                        setTags(selModel.entities)
        }else{
            setTags([])
        }
        setModel(e)
    }
    const handleRemoveTags = (e)=>{

        console.log(e)

            setRemoveTags(e)

    }


    return (
        <ManyosSpinner spinning={loading}>
        <Form>
            <Form.Item
                label={"Select Model"}
            >
                <Select

                    onSelect={handleModelSelect}
                    options={
                       models &&  models.map((model,i)=>({key:i,label:model.model,value:model.model}))
                    }
                />

            </Form.Item>


            {
                tags.length>0 &&
                (
                    <Form.Item
                        label={"Remove Tags"}
                    >
                        <Select
                            mode={"multiple"}
                            allowClear
                            value={removeTags}
                            onChange={handleRemoveTags}
                            options={
                                tags &&  tags.map((tag,i)=>({key:i,label:tag,value:tag}))
                            }
                        />
                    </Form.Item>
                )
            }


            <Form.Item
                label={"Teststring"}
            >
                <TextArea
                    value = {testString}
                    onChange={handleTestStringChange}
                />
            </Form.Item>

            <Form.Item

            >
                <Button
                    value = {testString}
                    onClick={()=>{
                            if (model && testString && testString.length>0){
                                const modeldef=models.find(m=>m.model==model)
                                 dispatch(testModel({testString,modeldef,removeTags}))
                            }else{
                                message.error("Enter a Teststring and select a model")
                            }

                        }
                    }
                >Run Test</Button>
            </Form.Item>



        </Form>
        {(testResult) &&
        <Text>{testResult}</Text>
        }
        </ManyosSpinner>
    )


}

export default connect(select)(TestModel)
