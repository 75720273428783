import React from "react"
import {Spin} from "antd"


const ManyosSpinner=({children,...rest})=>{

    const [logoIndicator,setLogoIndicator]=React.useState(undefined)


    React.useEffect(()=>{

            setLogoIndicator(<object type="image/svg+xml" data="/spinner.svg" className={"manyosSpin"} />)

    },[])



        return (
            <Spin  className={`loginSpin`}  indicator={logoIndicator} {...rest}>
                {children}
            </Spin>
        )

}

export default ManyosSpinner
