import {
    createAsyncThunk
} from "@reduxjs/toolkit";






export function smileConcealAPIcall({ url,requestOptions }){

           return fetch(url, requestOptions)
                    .then(async data => {

                            if (data.status && data.status>400){
                                let error = await data.json();
                                console.error(error)
                            }else{

                                return data.json()
                            }

                        }).catch(error=> {
                    console.error(error)
               })
                    .then(({ status, ...apiData}) => {

                        if ( ({...apiData}.hasOwnProperty("error") && {...apiData}.hasOwnProperty("stackTrace"))) {

                            const error = {...apiData};

                           throw Error(JSON.stringify(error))
                        } else {

                            return apiData ;
                        }
                    }).catch(error => {throw Error(error)});



}



export const saveTrainingData = createAsyncThunk("request/saveTrainingData",  async ( {data}) => {
    const response = await smileConcealAPIcall({url:window._env_.REACT_APP_API_URL+"/saveTrainingData",requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify(data)}});

    return response.data;
});

export const getTrainingDataList = createAsyncThunk("request/getTrainingDataList",  async ( ) => {
    const response = await smileConcealAPIcall({url:window._env_.REACT_APP_API_URL+"/getTrainingData",requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});

    return response.available_files;
});
export const getTrainingDataFile = createAsyncThunk("request/getTrainingDataFile",  async ( {file} ) => {
    const response = await smileConcealAPIcall({url:`${window._env_.REACT_APP_API_URL}/getTrainingData/${file}`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});

    return response.data;
});

export const getModels = createAsyncThunk("request/getModels",  async ( ) => {
    const response = await smileConcealAPIcall({url:`${window._env_.REACT_APP_API_URL}/getModels`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});
    console.log(response)
    return response.available_models;
});
export const createModel = createAsyncThunk("request/createModel",  async ( {modelName,baseModel,filename,language} ) => {

    const body={
        modelname:modelName,
        basemodel:baseModel,
        filename,
        language
    }
    const response = await smileConcealAPIcall({url:`${window._env_.REACT_APP_API_URL}/createModel`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify(body)}});

    return response.data;
});

export const testModel = createAsyncThunk("request/testModel",  async ( {testString,modeldef,removeTags} ) => {



    const configuration={
        "nlp_engine_name": "spacy",
            "models": [{"lang_code": modeldef.lang, "model_name": (modeldef.type=="custom") ? `./models/${modeldef.model}`:modeldef.model }]}

    const body={
        text:testString,
        removeTags,
        configuration
    }


    const response = await smileConcealAPIcall({url:`${window._env_.REACT_APP_API_URL}/anonymizeText`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:(JSON.stringify(body))}});

    return response.anonymized;
});
