import React,{useEffect,useState} from "react";

import {connect,useDispatch} from "react-redux";
import {getTrainingDataList,getTrainingDataFile,createModel,getModels} from "../../util/redux/asyncActions";
import {Select,Form,Input,Button} from 'antd'
import ManyosSpinner from "../../ManyosSpinner/ManyosSpinner";
const select = state =>{
    const files = state.request.files
    const loading  = state.request.loading
    const models = state.request.models
    return{
        files,
        loading,
        models
    }
}
function CreateModel(props){

    const {files,loading,models} = props
    const [filename,setFilename] = useState()
    const dispatch = useDispatch()
    const [baseModels,setBaseModels] = useState(
    )
    useEffect(()=>{
        dispatch(getTrainingDataList())
        dispatch(getModels())
    },[])
    useEffect(()=>{

        if(models){
            setBaseModels(models.filter(model=>model.type=="default").map(model=>model.model))
        }


    },[models])

    const  [baseModel,setBaseModel]= useState()
    const  [language,setLanguage]= useState()
    const  [modelName,setModelName]= useState()
    const handleFileSelect=(file)=>{
        setFilename(file)
        dispatch(getTrainingDataFile({file}))
    }
    const handleModelNameChange=(e)=>{
        setModelName(e.target.value)

    }
    const handleSelectBaseModel=(model)=>{
        const definition=models.find(m=>m.model===model)
        setBaseModel(model)
        setLanguage(definition.lang)

    }



    return (
        <ManyosSpinner spinning={loading}>
        <Form>
            {(files) && (
            <Form.Item
                label={"File"}
            >
                <Select
                    value={filename}
                    onSelect={handleFileSelect}
                    options={files.map((file,i)=>({key:`file-${i}`,value:file,label:file}))}
                />


            </Form.Item>)}
            {
                baseModels && <Form.Item
                    label={"Basemodel"}
                >
                    <Select
                        value={baseModel}
                        onSelect={handleSelectBaseModel}
                        options={baseModels.map((model,i)=>({key:`model-${i}`,value:model,label:model}))}
                    >

                    </Select>
                </Form.Item>
            }

            <Form.Item
                label={"Modelname"}
            >
                <Input
                    placeholder={"Modelname"}
                    value={modelName}
                    onChange={handleModelNameChange}

                >

                </Input>
            </Form.Item>
            <Form.Item

            >
                <Button

                    value={modelName}
                    onClick={()=>{
                        dispatch(createModel({modelName,filename,baseModel,language}))
                    }
                    }

                >
                    Create
                </Button>
            </Form.Item>

        </Form>
        </ManyosSpinner>
    )


}

export default connect(select)(CreateModel)
