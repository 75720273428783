import React, { useState } from 'react';
import {  Dropdown, Menu,Tag,Input,Typography } from 'antd';
import {TagOutlined} from '@ant-design/icons'
import {connect} from "react-redux";

const {Text} = Typography

const select = state =>{
    const activeTag=state.request.activeTag
    const tags=state.request.tags
    return {
        activeTag,allTags:tags
    }
}
const MyInputComponent = (props) => {

    const {value,onChange,tags,activeTag,allTags}=props;



    const [rowTags,setRowTags]=useState(tags||[])
    const [renderValue,setRenderValue]=useState([])
    function getRandomColor() {
        const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
        const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
        const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
        return '#' + red + green + blue;
    }


    const handleRemoveTag = (e,tag) => {
        e.preventDefault();


        const index = tags.findIndex(rtag=>{

            return tag.start === rtag.start && tag.end === rtag.end && tag.tag === rtag.tag
        })

        const newTags = tags.filter((rtag,i)=>i!==index)



        setRowTags(newTags)

    };
    React.useEffect(()=>{
      if(tags){
          const result = [];
          let lastIndex = 0;

          tags.forEach(({start, end,tag}) => {
              result.push({val:value.slice(lastIndex, start),type:"text"});
              result.push({val:value.slice(start, end),type:"tag",tag,start,end});
              lastIndex = end;
          });

          result.push({val:value.slice(lastIndex),type:"text"});
          setRenderValue(result)
      }else{
          setRenderValue([{val:value,type:"text"}])
      }
    },[tags])

    React.useEffect(()=>{

    onChange(rowTags)


    },[rowTags])
    const handleAddTag = (tag)=>{
        setRowTags([...rowTags,tag].sort((a,b)=>a.start-b.start))

    }


    function getWordPositions(text, position) {

        const words = text.split(' ');  // split the string into a list of words
        let wordCount = 0;  // initialize the word count
        for (let i = 0; i < words.length; i++) {
            const wordStart = wordCount;
            wordCount += words[i].length + 1;  // add the length of the word and a space to the word count
            const wordEnd = wordCount - 2;
            if (position >= wordStart && position <= wordEnd) {
                return { start: wordStart, end: wordEnd };  // return the start and end positions of the word
            }
        }
        return null;  // position is out of range, return null
    }



    const handleSelection = event => {


        event.preventDefault();



        if (activeTag && activeTag.indexOf("TAG-")>=0){

            const tagIndex = activeTag.slice(4)

            // Get the selected text and its position
            const selection = window.getSelection();
            const type = selection.anchorNode.parentNode.getAttribute("mytype")

            if (type === "TEXT"){
                const selectedText = selection.toString();
                const offset = parseInt(selection.anchorNode.parentNode.getAttribute("offset"))

                const range = selection.getRangeAt(0);

                if (range.startOffset == range.endOffset){

                    const pos=getWordPositions(selection.anchorNode.textContent, range.startOffset)

                    handleAddTag({
                        start:pos.start + offset,
                        end:pos.end + offset +1,
                        tag:allTags[Object.keys(allTags)[tagIndex]].tag
                    })

                }else{

                    handleAddTag({
                        start:range.startOffset + offset,
                        end:range.endOffset + offset,
                        tag:allTags[Object.keys(allTags)[tagIndex]].tag
                    })
                }
            }










        }



    }




    const [selectedText, setSelectedText] = useState('');

    const handleTextChange = (event) => {
        setSelectedText(event.target.value);
    };






    return (
        <>
            <div value={selectedText} onChange={handleTextChange}   onMouseUp={handleSelection} >
                {
                    renderValue.map((rval,i)=>{
                        if (rval.type=="text"){
                            let startOffset=0
                            if (i>0 &&  renderValue[i-1] && renderValue[i-1].type === "tag"){

                                startOffset = renderValue[i-1].end
                            }
                        return (

                            <Text key={"text-"+i} mytype="TEXT" style={{float:"left"}} offset={startOffset}>{rval.val}</Text>


                        )}else{
                            if (allTags && allTags.find(tag=>tag.tag===rval.tag)){
                                const color =allTags.find(tag=>tag.tag===rval.tag).color
                                return(
                                    <Tag key={"tag-"+i} mytype="TAG" color={color} style={{float:"left",marginLeft:"5px"}} closable onClose={(e)=>{handleRemoveTag(e,rval)}} >{`${rval.val}=[${rval.tag}]`}  </Tag>
                                )
                            }else{

                                return (rval.tag)
                            }

                        }
                    })
                }
            </div>

        </>
    );
};

export default connect(select)(MyInputComponent);
