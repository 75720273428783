import {Tag} from "antd";
import React from "react";
import {useDispatch,connect} from "react-redux";
import {setTags} from "../util/redux";





const select = state => {
    const tags = state.request.tags
    return {
        tags
    }
}
function MysTag(props){
    const {tags} = props
    const dispatch=useDispatch()

    const handleRemoveTag=(e,val,index)=>{
        e.preventDefault();


        const newTags = tags.filter((rtag,i)=>i!==index)

        dispatch(setTags(newTags))

    }
    const {color,index,val}=props
    return(
        <Tag key={"tag-"+index} color={color} style={{float:"left",marginLeft:"5px"}} closable onClose={(e)=>{handleRemoveTag(e,val,index)}} >{val}  </Tag>
    )
}
export default connect(select)(MysTag)

