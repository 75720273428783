import React from 'react';
import { Layout ,Tabs,Menu} from 'antd';
import { UploadOutlined} from '@ant-design/icons';

import PrepData from './components/View/PrepData/PrepData'
import CreateModel from "./components/View/CreateModel/CreateModel";
import TestModel from "./components/View/TestModel/TestModel";
import Tag from './components/Tag/Tag'
import Navigation from "./components/Navigation/Navigation";
import {Provider} from "react-redux";
import store from "./components/util/redux/index";
import {useDispatch,connect} from "react-redux";
import {setTags} from './components/util/redux/index'
import './App.css';
const {Sider,Content} = Layout


const App = (props) => {






    return (

        <Provider store={store}>
        <Layout>
            <Sider style={{backgroundColor:"rgba(253,253,253,0.4)"}} >
               <Navigation/>

            </Sider>
            <Layout>
                <Content >
                    <Tabs
                        defaultActiveKey="1"
                        centered
                        items={
                            [
                                {label:"Prepare Training Data",key:0,children:<PrepData/>},
                                {label:"Create Model",key:1,children:<CreateModel/>},
                                {label:"Test Model",key:2,children:<TestModel/>},

                            ]

                        }
                    />
                </Content>


            </Layout>
        </Layout>
        </Provider>

    );
};

export default App;
