import React, {useEffect, useState} from 'react'
import {Button, Input, Select, Table, Upload,Space,Modal,message} from "antd";
import {SaveOutlined, UploadOutlined} from "@ant-design/icons";
import {useDispatch,connect} from "react-redux";
import Papa from 'papaparse';
import {getRandomColor} from "../../util/shared_functions";
import ManyosSpinner from "../../ManyosSpinner/ManyosSpinner";

import {getModels, getTrainingDataFile, getTrainingDataList, saveTrainingData} from "../../util/redux/asyncActions";

import TextField from '../../TextField/TextField'


const { Option } = Select;
const {Dragger} = Upload


const select = state =>{
    const {loading,trainingData,files} = state.request
    return{loading,trainingData,files}
}


function PrepData(props){

    const {loading,trainingData,files}=props
    const [file, setFile] = useState(null);
    const [filterResults,setFilterResults] = useState (true)
    const [data, setData] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData,setFilteredData] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [downloadData, setDownloadData] = useState();
    const [refreshStat, setRefreshStat] = useState(false);
    const [tfilename,settFilename] = useState()
    const dispatch = useDispatch();

    const [stat,setStat] = useState({
        numberOfTags:0,
        rows:0,
        tags:[]
    });
    useEffect(()=>{
        dispatch(getTrainingDataList())
    },[])
    React.useEffect(()=>{
        if(trainingData && trainingData != ""){

            try{
                const tData=JSON.parse(trainingData)
                setData(tData)

                setSelectedColumn(Object.keys(tData[0])[0]);
                setTagList(getTags(tData))
            }catch(e){
                message.error(e.toString())
            }

        }

    },[trainingData])
    React.useEffect(()=>{
        console.log(data)

    },[data])
    React.useEffect(()=>{

        const allTags = []
        const rows=data.length
        data.forEach(row=> {

            if (row.tags) {
                {
                    row.tags.forEach(tag => {

                        if (allTags.indexOf(tag.tag) < 0) {
                            allTags.push(tag.tag)
                        }

                    })
                }
            }
        })
        const untaggedRows=data.filter(row=>!row.tags || row.tags.length ===0).length
        const taggedRows=data.filter(row=>row.tags && row.tags.length >0).length
        const tagStat=allTags.map(tag=>{
            let i=0
            data.forEach(row=>{
                if (row.tags){
                    i = i+ row.tags.filter(rtag=>rtag.tag ===tag).length
                }
            })
            return {
                [tag]:{
                    rowsContaining: data.filter(row=>{
                        return row.tags && row.tags.filter(rtag=>rtag.tag ===tag).length>0
                    }).length,
                    totalCount: i


                }
            }
        })
        const newStat={
            rows,untaggedRows,taggedRows,
            numberOfTags:allTags.length,
            tags:tagStat
        }

        setStat(newStat)

    },[refreshStat])



    function getTags(content){
        let allTags=tagList || []

        content.forEach(row=> {

            if (row.tags && Array.isArray(row.tags)) {
                {

                    row.tags.forEach(tag => {

                        if (allTags.filter(rtag => tag.tag===rtag.tag).length ===0) {
                            allTags.push({tag:tag.tag.toUpperCase(),color:getRandomColor()})
                        }

                    })
                }
            }
        })

        return allTags
    }
    const handleFileUpload = (file) => {
        setFile(file);

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {

            try{
                let content=JSON.parse(event.target.result)

                setData(content)

                setSelectedColumn(Object.keys(content[0])[0]);
                setTagList(getTags(content))
            }catch(e){

                Papa.parse(file, {
                    header: true,
                    complete: (result) => {

                        setData(result.data);
                        setSelectedColumn(Object.keys(result.data[0])[0]);
                        setTagList(getTags(result.data))
                    },
                    error: (error) => {
                        console.log(error);

                    }
                });
            }

        });

        reader.readAsText(file);





    };


    const onSelectChange = (newSelectedRowKeys) => {

        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handleColumnSelect = (value) => {
        setSelectedColumn(value);
    };

    const hasSelected = selectedRowKeys.length > 0;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const columns = data.length > 0
        ?

        [...Object.keys(data[0]).filter(colName=>colName==selectedColumn).map((colName) => {
            return {
                title: colName,
                dataIndex: colName,
                key: colName,
                render: (text, record, rowIndex) => {
                    return (


                        <TextField
                            key={`textfield-${rowIndex}`}
                            value={text}
                            tags={data[record.key].tags}
                            setTagList={setTagList}
                            tagList={tagList}
                            onChange={(tags) => {

                                const newData = [...data];

                                newData[record.key].tags = tags;

                                setData(newData);
                            }}

                        />

                    );
                }
            };
        })]
        : [];

    React.useEffect(()=>{
        setFilteredData(data.filter((row) => {
            const regex = new RegExp(searchQuery, 'gi');
            return regex.test(row[selectedColumn]);
        }))
    },[filterResults,data])

    const tableData = filteredData.map((row, rowIndex) => {
        const rowObj = {
            key: rowIndex,
            ...row

        };
        if (!rowObj.tags){
            rowObj.tags=""
        }

        return rowObj;
    });

    const handleFileSelect=(file)=>{
        settFilename(file)
        dispatch(getTrainingDataFile({file}))
    }

    let filename="taggedData.json"
    let contentType = "application/json;charset=utf-8;";

    return (<div style={{height:"100%"}}>
              <ManyosSpinner spinning={loading}>
            {(!file && (!trainingData || trainingData.length==0))
                ?(<Space><Dragger beforeUpload={handleFileUpload}>
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a SMILEconceal JSON File or Standard CSV File.
                        </p>
                </Dragger>
                <Select style={{width:"300px"}}
                    value={tfilename}
                    onSelect={handleFileSelect}
                    options={files.map((file,i)=>({key:`file-${i}`,value:file,label:file}))}
                /></Space>
                )
                :( <>


                    <Space>
                    <Button
                    style={{float:"left",marginRight:"20px"}}

                    onClick={()=>{

                        let filename;

                        Modal.info({
                            title:'Enter the filename',
                            content:<Input
                                placeholder={"Filename"}
                                onChange={(e)=>{filename=e.target.value}}
                            />,
                            onOk:()=>{
                                dispatch(saveTrainingData({data:{filename,data:JSON.stringify(data.map(row=>{return {text:row[selectedColumn],tags:row.tags}}))}}))
                                message.success("Sucessfully Saved")
                            }
                        }
                        )
                    }
                    }
                    icon={<SaveOutlined />}
                >Save</Button>

                </Space>
                <br />
                <Space>
                Column <Select value={selectedColumn} onChange={handleColumnSelect}>
            {data.length > 0 &&
                Object.keys(data[0]).map((colName) => {
                return <Option key={colName}>{colName}</Option>;
            })}
                </Select>
                <Space.Compact>
                <Input placeholder="filter using regex " onChange={handleSearch} />
                <Button onClick={()=>setFilterResults(!filterResults)} >Filter</Button>
                </Space.Compact>

                </Space></>)
            }





            <Table  dataSource={tableData} columns={columns} />
              </ManyosSpinner>





    </div>)
}

export default connect(select)(PrepData)
